<template>
  <div class="test" v-if="props.isLoading">
    <div id="loader" class="center"></div>
  </div>
</template>
<script setup lang="ts">
const props = defineProps(["isLoading"]);
const bodyTag = ref<any>(null);
useHead({
  bodyAttrs: {
    class: computed(() => {
      if (bodyTag.value) {
        bodyTag.value.classList.remove("overflow-hidden");
      }
      if (props.isLoading) return "overflow-hidden";

      return "";
    }),
  },
});

onMounted(() => {
  bodyTag.value = document.querySelector("body");
});
</script>